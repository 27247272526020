// Polyfills
import 'Utils/_closest.polyfill.js';
import 'Utils/cookies.js';

// Misc
// Use log() instead of console.log()
// const log = console.log.bind(console);

// Components
import { disablePageScroll, enablePageScroll, addFillGapTarget, addFillGapSelector, setFillGapMethod } from 'scroll-lock';
import Swiper, { Pagination, Navigation, EffectFade, EffectCreative } from 'swiper';


document.addEventListener('DOMContentLoaded', function() {
	// obtain plugin
	var cc = initCookieConsent();

	// run plugin with your configuration
	cc.run({
		current_lang: 'fr',
		autoclear_cookies: true,                   // default: false
		page_scripts: true,                        // default: false

		// mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
		// delay: 0,                               // default: 0
		// auto_language: '',                      // default: null; could also be 'browser' or 'document'
		// autorun: true,                          // default: true
		// force_consent: false,                   // default: false
		// hide_from_bots: true,                   // default: true
		// remove_cookie_tables: false             // default: false
		// cookie_name: 'cc_cookie',               // default: 'cc_cookie'
		// cookie_expiration: 182,                 // default: 182 (days)
		// cookie_necessary_only_expiration: 182   // default: disabled
		// cookie_domain: location.hostname,       // default: current domain
		// cookie_path: '/',                       // default: root
		// cookie_same_site: 'Lax',                // default: 'Lax'
		// use_rfc_cookie: false,                  // default: false
		// revision: 0,                            // default: 0

		onFirstAction: function(user_preferences, cookie){
			// callback triggered only once on the first accept/reject action
		},

		onAccept: function (cookie) {
			// callback triggered on the first accept/reject action, and after each page load
		},

		onChange: function (cookie, changed_categories) {
			// callback triggered when user changes preferences after consent has already been given
		},

		languages: {
			'fr': {
				consent_modal: {
					description: 'Ce site internet utilise des cookies pour vous fournir la meilleure expérience de navigation.',
					primary_btn: {
						text: 'Ok',
						role: 'accept_all'              // 'accept_selected' or 'accept_all'
					},
				},
				settings_modal: {
					title: 'Cookie preferences',
					save_settings_btn: 'Save settings',
					accept_all_btn: 'Accept all',
					close_btn_label: 'Close',
					// cookie_table_caption: 'Cookie list',
					cookie_table_headers: [
						{col1: 'Name'},
						{col2: 'Domain'},
						{col3: 'Expiration'},
						{col4: 'Description'}
					],
					blocks: [
						{
							title: 'Cookie usage 📢',
							description: 'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="#" class="cc-link">privacy policy</a>.'
						}, {
							title: 'Strictly necessary cookies',
							description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
							toggle: {
								value: 'necessary',
								enabled: true,
								readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
							}
						}, {
							title: 'Performance and Analytics cookies',
							description: 'These cookies allow the website to remember the choices you have made in the past',
							toggle: {
								value: 'analytics',     // your cookie category
								enabled: false,
								readonly: false
							},
							cookie_table: [             // list of all expected cookies
								{
									col1: '^_ga',       // match all cookies starting with "_ga"
									col2: 'google.com',
									col3: '2 years',
									col4: 'description ...',
									is_regex: true
								},
								{
									col1: '_gid',
									col2: 'google.com',
									col3: '1 day',
									col4: 'description ...',
								}
							]
						}, {
							title: 'Advertisement and Targeting cookies',
							description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
							toggle: {
								value: 'targeting',
								enabled: false,
								readonly: false
							}
						}, {
							title: 'More information',
							description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="#yourcontactpage">contact us</a>.',
						}
					]
				}
			}
		}
	});

	let mm = gsap.matchMedia();
	var body = document.body;
    var $scrollableElement = document.querySelector('.my-scrollable-element');
	var method = 'margin'; //Available methods: none, padding, margin, width, max-width
    setFillGapMethod(method);

	// POPUPVIDEO
	var popup = document.getElementById('popup');
	var popupVideo = document.getElementById('popupVideo');
	var popupPlay = document.getElementById('popupPlay');
	var popupClose = document.getElementById('popupClose');

	if(popup) {
		popupPlay.addEventListener('click', function() {		
			if (body.classList.contains('popup-open')) { 
				body.classList.remove('popup-open');
				popupVideo.pause();
				popupVideo.muted = true;
				enablePageScroll($scrollableElement);
				document.querySelector('.no-js').style.overflow = "auto";

			} else {
				body.classList.add('popup-open');
				popupVideo.play();
				popupVideo.muted = false;
				disablePageScroll($scrollableElement);
				document.querySelector('.no-js').style.overflow = "hidden";
			}
		})

		popupClose.addEventListener('click', function() {		
			body.classList.remove('popup-open');
			popupVideo.pause();
			popupVideo.muted = true;

			enablePageScroll($scrollableElement);
			document.querySelector('.no-js').style.overflow = "auto";
		})

		popupVideo.addEventListener('webkitendfullscreen', (e) => { 
			//code to destroy my custom modal on close of x icon in ios native close button 
			body.classList.remove('popup-open');
			popupVideo.pause();
			popupVideo.muted = true;

			enablePageScroll($scrollableElement);
			document.querySelector('.no-js').style.overflow = "auto";
		});



	}

	document.onkeydown = function(evt) {
		evt = evt || window.event;
		var isEscape = false;
		if ("key" in evt) {
			isEscape = (evt.key === "Escape" || evt.key === "Esc");
		} else {
			isEscape = (evt.keyCode === 27);
		}
		if (popup) {
			if (body.classList.contains('popup-open')) { 
				body.classList.remove('popup-open');
				popupVideo.pause();
				popupVideo.muted = true;
				enablePageScroll($scrollableElement);
				document.querySelector('.no-js').style.overflow = "auto";
			}
		}
	};


	// APPEAR ANIMATION
	gsap.to('.section.--first', {
		//ease: "none",
		opacity:1,
	});

	gsap.utils.toArray(".banner .img-container").forEach(function(container) {
		let image = container.querySelector("img");
	  
		  gsap.to(image, {
			y: () => image.offsetHeight - container.offsetHeight,
			ease: "none",
			scrollTrigger: {
			  trigger: ".navbar",
			  start: "top top",
			  endTrigger: container,
			  end: "bottom top",
			  scrub: true,
			  pin: false,
			  //markers: true,
			  invalidateOnRefresh: true
			},
		  }); 
	});
	
	/*
	const sectionsFadeAppear = document.querySelectorAll(".section.--fade:not(.--multi)");
	sectionsFadeAppear.forEach(sectionFadeAppear => {		
		gsap.to(sectionFadeAppear, {
			//ease: "none",
			opacity:1,
			scrollTrigger: {
				trigger: sectionFadeAppear,
				start: "top 78%",
				//markers: true,
			}, 
		});
	})

	const sectionsFadeMultiAppear = document.querySelectorAll(".section.--fade.--multi");
	sectionsFadeMultiAppear.forEach(sectionFadeMultiAppear => {		
		const sectionsFadeMultiStaggerAppear = sectionFadeMultiAppear.querySelectorAll(".stagger-item");

		gsap.to(sectionsFadeMultiStaggerAppear, {
			//ease: "none",
			opacity:1,
			stagger: 0.1,
			scrollTrigger: {
				trigger: sectionFadeMultiAppear,
				start: "top 78%",
				//markers: true,
			}, 
		});
	})

	const sectionsTranslateAppear = document.querySelectorAll(".section.--translate");
	sectionsTranslateAppear.forEach(sectionTranslateAppear => {		
		const sectionsTranslateStaggerAppear = sectionTranslateAppear.querySelectorAll(".stagger-item");

		gsap.to(sectionsTranslateStaggerAppear, {
			//ease: "none",
			opacity:1,
			y:0,
			stagger: 0.1,
			scrollTrigger: {
				trigger: sectionTranslateAppear,
				start: "top 78%",
				//markers: true,
			}, 
		});
	})
	*/

	// HEADER TINY ON SCROLL
	/*
	mm.add("(min-width: 992px)", () => {
		ScrollTrigger.create({
			start: "top -100px",
			//scroller: scrollerBox,
			end: 99999,
			//markers: true,

			toggleClass: { targets: 'header', className: '--tiny' },
		});
	});

	mm.add("(max-width: 991px)", () => {
		ScrollTrigger.create({
			start: "top -30px",
			//scroller: scrollerBox,
			end: 99999,
			//markers: true,

			toggleClass: { targets: 'header', className: '--tiny' },
		});
	});
	*/

	// SWIPER STEPS
	const swiperSteps = new Swiper('.steps .swiper-container', {
		modules: [Navigation, EffectCreative],
		loop: false,
		grabCursor: true,
		//centeredSlides: true,
		spaceBetween: 76,
		//freeMode: true,
		slidesPerView: 1,
		effect: 'creative',
        autoHeight: true,
        creativeEffect: {
			limitProgress:3,
			prev: {
				//shadow: true,
				translate: [0, 0, -400],
				opacity: 0,
			  },
			  next: {
				translate: ["100%", 0, 0],
			  },
		  },
		  breakpoints: {
			// when window width is >= 320px
			320: {
			  //slidesPerView: 2,
			  //spaceBetween: 20
			},
			// when window width is >= 768px
			768: {
				spaceBetween: 30,
			},
			// when window width is >= 992px
			1400: {
				//centeredSlides: true,
				//freeMode: true,
				//slidesPerView: 1,
				//spaceBetween: 35,
				slidesPerView: 2,

				creativeEffect: {
					limitProgress:5,
				}
			}
		},
		  /*
		  creativeEffect: {
			prev: {
			  shadow: true,
			  translate: ["-20%", 0, -1],
			  opacity: 0,
			},
			next: {
			  translate: ["100%", 0, 0],
			},
		  },
		  */
		navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        }, 
	});

	//swiperSteps.updateAutoHeight(500);

	setTimeout(() => {
		//console.log("Delayed for 1 second.");
		swiperSteps.update()
	}, "1000");
	
	
	// FAQ ACCORDION
	const groups = gsap.utils.toArray(".accordion-item");
	const menus = gsap.utils.toArray(".accordion-question");
	const animations = [];

	function createAccordion() {
		groups.forEach(group => createAnimation(group));

		menus.forEach(menu => {
			menu.addEventListener("click", () => toggleAnimation(menu));
		});

		function toggleAnimation(menu) {	
			// Save the current state of the clicked animation
			const selectedReversedState = menu.animation.reversed();
			
			// Reverse all animations
			animations.forEach(animation => animation.reverse());
			groups.forEach(group => group.classList.remove('active'));
			menu.style.pointerEvents = "none";

			// Set the reversed state of the clicked accordion element to the opposite of what it was before
			menu.animation.reversed(!selectedReversedState);
		}

		function createAnimation(element) {
			const menu = element.querySelector(".accordion-question");
			const box  = element.querySelector(".accordion-content");
			
			gsap.set(box, { height: "auto"})
			menu.style.pointerEvents = "auto";

			// Keep a reference to the animation on the menu item itself
			const tween = gsap.from(box, { 
				height: 0, 
				duration: 0.5, 
				ease: "power1.inOut",
				reversed: true,
				
				onStart() {
					element.classList.add('active');
					menu.style.pointerEvents = "none";
				},

				onComplete() {
					//element.classList.add('active');
					menu.style.pointerEvents = "auto";
				},

				onReverseComplete() {
					//element.classList.add('active');
					menu.style.pointerEvents = "auto";
				},
			});
			
			menu.animation = tween;
			animations.push(tween);
		}
	}

	createAccordion();

	addEventListener("resize", (event) => {
		//console.log('test');

		groups.forEach(group => group.classList.remove('active'));

		groups.forEach(group => createAnimation(group));
		
		function createAnimation(element) {
			const menu = element.querySelector(".accordion-question");
			const box  = element.querySelector(".accordion-content");
			
			gsap.set(box, { height: "auto"})
			menu.style.pointerEvents = "auto";

			// Keep a reference to the animation on the menu item itself
			const tween = gsap.from(box, { 
				height: 0, 
				duration: 0.5, 
				ease: "power1.inOut",
				reversed: true,
				
				onStart() {
					element.classList.add('active');
					menu.style.pointerEvents = "none";
				},

				onComplete() {
					//element.classList.add('active');
					menu.style.pointerEvents = "auto";
				},

				onReverseComplete() {
					//element.classList.add('active');
					menu.style.pointerEvents = "auto";
				},
			});
			
			menu.animation = tween;
			animations.push(tween);
		}

		swiperSteps.update();
		
	});

	/*
	// SWIPER TESTIMONIALS
	const swiperTestimonials = new Swiper('.testimonials .swiper-container', {
		modules: [Navigation, EffectFade],
		loop: true,
		grabCursor: true,
		//centeredSlides: true,
		spaceBetween: 12,
		//freeMode: true,
		slidesPerView: 1,
		effect: 'fade',
        autoHeight: true,
        fadeEffect: {
          crossFade: true
        },
		navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        }, 

		breakpoints: {
			// when window width is >= 320px
			320: {
			  //slidesPerView: 2,
			  //spaceBetween: 20
			},
			// when window width is >= 768px
			768: {
				spaceBetween: 30,
			},
			// when window width is >= 992px
			992: {
				//centeredSlides: true,
				//freeMode: true,
				//slidesPerView: 1,
				//spaceBetween: 35,
			}
		},
	});

	setTimeout(() => {
		//console.log("Delayed for 1 second.");
		swiperTestimonials.update()
	}, "1000");
	*/
})